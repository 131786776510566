// -----------------------------------------------------------------------------
// Type utilities
// -----------------------------------------------------------------------------

@use 'config/responsive';

.font-heading {
    font-family: 'hwt-artz', sans-serif;
}



// Sizes
.size-current {
    font-size: 1em;
    line-height: inherit;
}

.size-body {
    font-size: 24px;
    line-height: 1.15;

    @include responsive.mq($from: 'sm') {
        font-size: 28px;
        line-height: 1.15;
    }
}



// Alignment
.align-center {
    text-align: center;
}
