// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------

.footer {
    padding: clamp(60px, 8.5vw, 85px) clamp(20px, 3vw, 30px) 85px;
    font-size: clamp(0.875rem, 2vw, 1.25rem);
    text-align: center;
}

    .footer__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 40em;
    }

        .footer__logo {
            align-self: center;
            width: clamp(110px, 13vw, 130px);
            margin-block: clamp(32px, 5vw, 50px);
        }
