// -----------------------------------------------------------------------------
// Figure
// -----------------------------------------------------------------------------

@use 'config/responsive';

.figure {
    @include responsive.mq($from: 'md') {
        --caption-width: 45fr;
        --image-width: 55fr;
        display: grid;
        height: clamp(700px, 66.666vw, 960px);

        &:nth-child(odd) {
            grid-template-areas: 'caption image';
            grid-template-columns: var(--caption-width) var(--image-width);
        }

        &:nth-child(even) {
            grid-template-areas: 'image caption';
            grid-template-columns: var(--image-width) var(--caption-width);
        }

        &:first-child {
            height: clamp(800px, 75vw, 1100px);
        }
    }

    @include responsive.mq($from: 'lg') {
        --caption-width: 42fr;
        --image-width: 58fr;
    }
}

    .figure__clear {
        @include responsive.mq($until: 'md') {
            display: none;
        }
    }

    .figure__image {
        grid-area: image;
        overflow: hidden;
        background-color: rgba(#000, 0.1);

        @include responsive.mq($until: 'md') {
            height: 600px;
        }
    }

        .figure__layer {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .figure__layer--background {
            z-index: 1;
        }

        .figure__layer--foreground {
            z-index: 2;
        }

        .figure__layer--sausage-roll {
            filter: drop-shadow(5px 5px 25px rgba(#000, 0.333));
        }

    .figure__caption {
        grid-area: caption;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: var(--page-padding);
        padding-right: var(--page-padding);

        @include responsive.mq($until: 'md') {
            padding-top: 10%;
            padding-bottom: 10%;
        }
    }

        .figure__lockup--small {
            width: clamp(400px, 66.666vw, 510px);

            @include responsive.mq($from: 'md') {
                display: none;
            }
        }

        .figure__lockup--large {
            @include responsive.mq($until: 'md') {
                display: none;
            }
        }
