// -----------------------------------------------------------------------------
// Image overlay block
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/responsive';

.image-overlay__background {
    z-index: 1;
    height: 100vh;
    background-attachment: fixed;
}

.image-overlay__foreground {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 60px clamp(50px, 10vw, 100px);
    font-size: clamp(100px, 10vw, 144px);
    line-height: 0.9;
    background-color: rgba(#000, 0.3);

    @include responsive.mq($from: 'md') {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include responsive.mq($from: 'lg') {
        justify-content: center;
    }
}

    .image-overlay__caption--small {
        align-self: center;
        width: clamp(280px, 50vw, 450px);

        @include responsive.mq($from: 'lg') {
            display: none;
        }
    }

    .image-overlay__caption--large {
        @include responsive.mq($until: 'lg') {
            display: none;
        }
    }



// Background images
$images: (
    pie: url('../../public/images/lifestyle/pie.jpg'),
);

@each $name, $image in $images {
    .image-overlay__background--#{$name} {
        background-image: $image;
    }
}
