// -----------------------------------------------------------------------------
// Gold stripe
// -----------------------------------------------------------------------------

@use 'config/colors';

.gold {
    padding-bottom: clamp(10px, 1.6vw, 16px);
    background-image: linear-gradient(to right, colors.$dark-gold, colors.$gold, colors.$dark-gold);
}
