// -----------------------------------------------------------------------------
// Button
// -----------------------------------------------------------------------------

@use 'config/responsive';

.button {
    display: inline-block;
    border: 0;
    border-radius: 500px;
    margin: 0;
    padding: 0.8em 1.25em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    @include responsive.mq($from: 'sm') {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }
}
