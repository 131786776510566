// -----------------------------------------------------------------------------
// Search
// -----------------------------------------------------------------------------

@use 'config/responsive';

.search__button {
    display: flex;
    width: 100%;
    border: 0;
    padding: 0.6875em 0.78125em 0.6875em 0.8em;
    font-size: clamp(26px, 3.2vw, 32px);
    line-height: 1.2;
    text-align: left;
    cursor: pointer;

    @include responsive.mq($from: 'sm') {
        padding-left: 1.125em;
    }
}

    .search__label {
        flex-grow: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .search__icon {
        flex-shrink: 0;
        margin-left: auto;

        @include responsive.mq($until: 'md') {
            width: 22px;
        }
    }

.search__options {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 5px;
    border-top: 1px solid rgba(#000, 0.1);
    padding: 5px;

    @include responsive.mq($from: 'md') {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-auto-rows: 1fr;
        padding: 12px;
    }

    @include responsive.mq($from: 'xl') {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.search__results {
    margin-top: clamp(40px, 13vw, 130px);
}
