// -----------------------------------------------------------------------------
// Theme utilities
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

// Background
.bg-white {
    background-color: #fff;
}

.bg-red {
    background-color: colors.$red;
}

.bg-dark-red {
    background-color: colors.$dark-red;
}

.bg-peach {
    background-color: colors.$peach;
}

.bg-dark-peach {
    background-color: colors.$dark-peach;
}

.bg-accent {
    background-color: var(--accent-color);
}



// Background image
.bg-fill {
    background-size: cover;
    background-position: center;
}

.bg-rays {
    background-image: url('../../public/images/rays-square.svg');
    background-blend-mode: overlay;
}

.bg-pattern {
    background-image: url('../../public/images/pattern.jpg');
    background-position: center;

    @include responsive.mq($until: 'lg') {
        background-size: 200px;
    }
}



// Foreground
.fg-white {
    color: #fff;
}

.fg-red {
    color: colors.$red;
}

.fg-dark-red {
    color: colors.$dark-red;
}

.fg-peach {
    color: colors.$peach;
}

.fg-dark-peach {
    color: colors.$dark-peach;
}

.fg-gray {
    color: colors.$gray;
}

.fg-accent {
    color: var(--accent-color);
}



// Accent
.accent-dark-red {
    --accent-color: #{colors.$dark-red};
}

.accent-dark-peach {
    --accent-color: #{colors.$dark-peach};
}



// Fill
.fill-current {
    fill: currentColor;
}

.fill-accent {
    fill: var(--accent-color);
}

.fill-white {
    fill: #fff;
}

.fill-peach {
    fill: colors.$peach;
}
