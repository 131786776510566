// -----------------------------------------------------------------------------
// Range
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/colors';
@use 'config/responsive';

.range {
    text-align: center;

    &:nth-child(odd) {
        --grid-template: 'caption product';
        --caption-align: flex-end;
        --caption-spacing-left: var(--page-padding);
        --image-align: flex-start;
    }

    &:nth-child(even) {
        --grid-template: 'product caption';
        --caption-align: flex-start;
        --caption-spacing-right: var(--page-padding);
        --image-align: flex-end;
    }
}

    .range__wrapper {
        display: flex;
        flex-direction: column;
        padding-top: clamp(100px, 16.666vw, 200px);

        @include responsive.mq($from: 'xl') {
            padding-bottom: 75px;
        }
    }

        .range__heading {
            z-index: 1;
            overflow: visible;
        }

        .range__grid {
            z-index: 2;

            @include responsive.mq($from: 'lg') {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-areas: var(--grid-template);
            }
        }

            .range__product {
                grid-area: product;
                display: flex;
                align-items: flex-start;
                justify-content: var(--image-align);
            }

                .range__image {
                    flex-shrink: 0;
                    max-width: none;
                }

                .range__image--sausage-roll {
                    width: 70%;
                    margin-top: -35px;

                    @include responsive.mq($from: 'sm') {
                        margin-top: -75px;
                    }

                    @include responsive.mq($until: 'lg') {
                        margin-left: auto;
                        transform: translateX(33%) rotate(-3deg);
                    }

                    @include responsive.mq($from: 'lg') {
                        width: percentage(math.div(810, 800));
                        margin-left: 140px;
                        transform: rotate(-3deg);
                    }
                }

                .range__image--chicken-slice {
                    width: 70%;
                    margin-top: -30px;

                    @include responsive.mq($from: 'sm') {
                        margin-top: -60px;
                    }

                    @include responsive.mq($until: 'lg') {
                        transform: translateX(-30%);
                    }

                    @include responsive.mq($from: 'lg') {
                        width: percentage(math.div(969, 800));
                        margin-right: 45px;
                    }
                }

                .range__image--pork-pie {
                    width: 40%;
                    margin-top: -35px;

                    @include responsive.mq($from: 'sm') {
                        margin-top: -65px;
                    }

                    @include responsive.mq($until: 'lg') {
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @include responsive.mq($from: 'lg') {
                        width: percentage(math.div(672, 800));
                        margin-top: -250px;
                        margin-left: 180px;
                    }

                    @include responsive.mq($from: 'xl') {
                        margin-top: -490px;
                        margin-left: 250px;
                    }
                }

                .range__image--scotch-egg {
                    width: 48%;
                    margin-top: -20px;

                    @include responsive.mq($from: 'sm') {
                        margin-top: -40px;
                    }

                    @include responsive.mq($until: 'lg') {
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @include responsive.mq($from: 'lg') {
                        width: percentage(math.div(742, 800));
                        margin-top: -65px;
                        margin-right: 120px;
                    }
                }

            .range__caption {
                grid-area: caption;
                display: flex;
                justify-content: var(--caption-align);
                padding-top: clamp(35px, 5vw, 50px);

                @include responsive.mq($until: 'lg') {
                    padding-left: var(--page-padding);
                    padding-right: var(--page-padding);
                }

                @include responsive.mq($from: 'lg') {
                    margin-left: var(--caption-spacing-left);
                    margin-right: var(--caption-spacing-right);
                    padding-top: 72px;
                }
            }

                .range__description {
                    @include responsive.mq($until: 'lg') {
                        max-width: 18em;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @include responsive.mq($from: 'lg') {
                        max-width: 23em;
                    }
                }

                    .range__button {
                        margin-top: clamp(32px, 5.2vw, 52px);
                    }
