// -----------------------------------------------------------------------------
// Social block
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

.social__wrapper {
    padding-top: clamp(60px, 9vw, 90px);
    text-align: center;
}

    .social__heading {
        font-size: clamp(60px, 10vw, 120px);
        line-height: 1;
    }

    .social__feed {
        margin-block: clamp(50px, 10vw, 130px);
    }



// Social feed
.social-feed {
    display: flex;
    justify-content: center;
}

    .social-feed__image {
        flex-shrink: 0;
        width: clamp(100px, 25vw, 325px);

        &:nth-child(odd) {
            transform: rotate(-6.75deg);
        }

        &:nth-child(even) {
            transform: rotate(2deg);
        }
    }



// Social menu
.social-menu {
    display: flex;
    justify-content: center;
    font-size: clamp(34px, 5vw, 52px);
}

    .social-menu__item {
        & + & {
            margin-left: 0.333em;

            @include responsive.mq($from: 'sm') {
                margin-left: 0.5em;
            }

            @include responsive.mq($from: 'lg') {
                margin-left: 0.85em;
            }
        }
    }

        .social-menu__link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.7em;
            height: 1.7em;
            border-radius: 50%;
            color: #fff;
        }

            .social-menu__icon {
                fill: currentColor;
            }
