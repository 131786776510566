// -----------------------------------------------------------------------------
// “Officially Britain’s favourite” block
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/colors';
@use 'config/responsive';

.favourite__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: clamp(45px, 6.5vw, 65px);
    padding-bottom: clamp(60px, 9vw, 90px);
}

    .favourite__wrapper {
        max-width: clamp(450px, 69.6vw, 696px);

        &:last-child {
            @include responsive.mq($until: 'md') {
                max-width: none;
            }
        }
    }

        .favourite__heading {
            width: percentage(math.div(656, 696));
        }

        .favourite__image {
            width: 80vw;
            margin-top: 50px;
            margin-bottom: 50px;

            @include responsive.mq($from: 'sm') {
                width: min(990px, 66.666vw);
                margin-top: 70px;
                margin-bottom: clamp(70px, 12vw, 120px);
            }
        }

        .favourite__description {
            width: 100%;
        }
