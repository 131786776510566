// -----------------------------------------------------------------------------
// Curve
// -----------------------------------------------------------------------------

@use 'config/responsive';

:root {
    --curve-width: 800px;
    --curve-height: 90px;

    @include responsive.mq($from: 'sm') {
        --curve-width: 1200px;
        --curve-height: 120px;
    }

    @include responsive.mq($from: 'lg') {
        --curve-width: 1600px;
        --curve-height: 140px;
    }

    @include responsive.mq($from: '2xl') {
        --curve-width: 100vw;
    }
}

.curve {
    min-height: var(--curve-height);
    margin-top: calc((var(--curve-height) * -1) - 2px);
    mask-image:
        url('../../public/images/curve.svg'),
        linear-gradient(#fff, #fff);
    mask-repeat: no-repeat;
    mask-size:
        var(--curve-width) calc(var(--curve-height) * 1.1),
        100% 100%;
    mask-position:
        50% 0,
        0 calc(var(--curve-height) * 1.05);
}

.clear-curve {
    height: var(--curve-height);
}
