// -----------------------------------------------------------------------------
// Product select button
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/colors';
@use 'config/responsive';

.product-button {
    display: flex;
    flex-direction: column;
    border: 0;
    padding: 16px 8px;
    font-size: 22px;
    line-height: 1;
    color: colors.$gray;
    background-color: transparent;
    cursor: pointer;

    @include responsive.mq($from: 'sm') {
        padding-left: 16px;
        padding-right: 16px;
    }

    @include responsive.mq($from: 'md') {
        font-size: 26px;
    }

    @include responsive.mq($from: 'lg', $until: 'lg') {
        font-size: 28px;
    }
}

.product-button:hover,
.product-button:focus,
.product-button--selected {
    color: colors.$red;
    background-color: colors.$peach;
}

    .product-button__canvas {
        align-self: center;
        width: 100%;
        max-width: 66.666%;
        margin-bottom: 12px;

        @include responsive.mq($from: 'sm') {
            margin-bottom: 18px;
        }

        @include responsive.mq($from: 'md') {
            width: percentage(math.div(200, 232));
            max-width: 200px;
        }
    }
