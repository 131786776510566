// -----------------------------------------------------------------------------
// Overlay
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

.overlay {
    position: fixed;
    inset: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: rgba(#000, 0.4);

    &[aria-hidden='true'] {
        display: none;
    }
}

    .overlay__content {
        @include responsive.mq($until: 'sm') {
            flex-grow: 1;
        }

        @include responsive.mq($from: 'sm') {
            padding: clamp(20px, 5vw, 75px);
        }
    }
