// -----------------------------------------------------------------------------
// Contact block
// -----------------------------------------------------------------------------

@use 'config/responsive';

.contact {
    padding-top: clamp(100px, 16.5vw, 165px);
    padding-bottom: clamp(80px, 11.5vw, 115px);
    text-align: center;
}

    .contact__heading {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.25em;
        font-size: clamp(58px, 12.5vw, 75px);
        line-height: 0.9;

        @include responsive.mq($from: 'sm') {
            font-size: clamp(90px, 10vw, 120px);
        }

        > span:first-child {
            font-size: 0.8em;
            line-height: 1;
        }
    }

    .contact__intro {
        max-width: 18em;
        margin-bottom: clamp(38px, 4.5vw, 45px);
    }
