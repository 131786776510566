// -----------------------------------------------------------------------------
// Type container
// -----------------------------------------------------------------------------

.type {
    p,
    ul,
    ol {
        margin-bottom: 1em;
    }

    h2,
    h3 {
        margin-top: 1.2em;
        margin-bottom: 0.5em;
        font-family: 'hwt-artz', sans-serif;
        line-height: 1.125;
    }

    h2 {
        font-size: 2.333em;
    }

    h3 {
        font-size: 1.666em;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}
