// -----------------------------------------------------------------------------
// Shadow utilities
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

// Text shadow
.text-shadow {
    text-shadow: 0 0 0.5em var(--shadow-color);
}

.text-shadow-lg {
    text-shadow: 0 0 1em var(--shadow-color);
}



// Product image shadow
.product-shadow {
    filter: drop-shadow(5px 5px 12px var(--shadow-color));

    @include responsive.mq($from: 'sm') {
        filter: drop-shadow(10px 10px 25px var(--shadow-color));
    }
}



// Shadow colours
.shadow-dark-peach {
    --shadow-color: #{colors.$dark-peach};
}

.shadow-dark-red {
    --shadow-color: #{colors.$dark-red};
}
