// -----------------------------------------------------------------------------
// Color palette
// -----------------------------------------------------------------------------

$red: #a4271d;
$dark-red: #690603;
$blue: #2d358c;
$peach: #f8d09d;
$dark-peach: #f1aa61;
$gold: #c2b588;
$dark-gold: #ac9c63;
$gray: #aaa;
