// -----------------------------------------------------------------------------
// Page
// -----------------------------------------------------------------------------

@use 'config/colors';

:root {
    --header-height: clamp(30px, 5vw, 50px);
    --page-padding: clamp(30px, 5vw, 50px);
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    padding-top: var(--header-height);
    color: #fff;
    background-color: colors.$dark-red;
}

    .page__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 40;
        height: var(--header-height);
    }

    .page__underlay {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .page__underlay--top {
        top: var(--header-height);
    }

    .page__overlay {
        z-index: 2;
    }

    .page__padding {
        padding-left: var(--page-padding);
        padding-right: var(--page-padding);
    }

    .page__footer {
        //z-index: 30;
        background-color: colors.$dark-red;
    }
