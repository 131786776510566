// -----------------------------------------------------------------------------
// “There’s a Wall’s for everyone” feature
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/responsive';

.everyone {
    position: relative;
    overflow: hidden;
}

    .everyone__caption {
        max-width: 600px;
        padding-top: 170px;
        padding-bottom: 130px;

        @include responsive.mq($from: 'md') {
            padding-top: clamp(260px, 36vw, 360px);
            padding-bottom: clamp(150px, 28vw, 280px);
        }

        @include responsive.mq($from: 'lg') {
            max-width: 760px;
        }
    }

        .everyone__heading {
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            font-size: clamp(56px, 8.333vw, 64px);
            line-height: 0.9;

            @include responsive.mq($from: 'md') {
                margin-bottom: 15px;
                font-size: 80px;
            }

            @include responsive.mq($until: 'lg') {
                strong {
                    display: block;
                    font-size: 1.75em;
                }
            }

            @include responsive.mq($from: 'lg') {
                font-size: 120px;

                > span:nth-child(2) {
                    font-size: 0.8em;
                }
            }
        }

    .everyone__hand {
        position: absolute;
    }

    .everyone__hand--top-left {
        top: clamp(100px, 23.5vw, 235px);
        left: 0;
        width: 110px;

        @include responsive.mq($from: 'sm') {
            width: 150px;
        }

        @include responsive.mq($from: 'md') {
            width: min(408px, percentage(math.div(408, 1600)));
        }
    }

    .everyone__hand--bottom-left {
        bottom: 0;
        left: 0;
        width: 110px;

        @include responsive.mq($from: 'sm') {
            width: 150px;
        }

        @include responsive.mq($from: 'md') {
            width: min(414px, percentage(math.div(414, 1600)));
        }
    }

    .everyone__hand--right {
        top: clamp(100px, 12vw, 120px);
        right: 0;
        width: 130px;

        @include responsive.mq($from: 'sm') {
            width: 160px;
        }

        @include responsive.mq($from: 'md') {
            width: min(470px, percentage(math.div(470, 1600)));
        }
    }
